<script setup>
import {
  onMounted,
  computed,
  reactive,
  defineExpose,
  defineProps,
  defineEmits,
  toRefs,
} from "vue";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";

const emits = defineEmits(["saveForm"]);

const props = defineProps({
  inputRules: {
    type: Object,
    required: true,
  },
  inputState: {
    type: Object,
    required: true,
  },
  inputsDetails: {
    type: Array,
    required: true,
  },
  showSaveButton: {
    type: Boolean,
    default: false,
  },
  multiple_options: {
    type: Boolean,
    default: false,
  },
  disabledInputs: {
    type: Boolean,
    default: false,
  },
});
const { inputState, inputRules } = toRefs(props);
const store = useStore();

const v$ = useVuelidate(inputRules, inputState);
const inputs = reactive(
  props.inputsDetails.map((item) => {
    return {
      title: item.title,
      model: v$.value[item.name],
      placeholder: item.placeholder,
      ...item,
    };
  })
);

const checkValidations = async () => {
  const result = await Promise.all(
    Object.keys(props.inputState).map(async (item) => {
      return v$.value[item]?.$errors.length ? true : false;
    })
  );
  return result.filter(Boolean).length ? true : false;
};

onMounted(async () => {
  await store.dispatch("admin/loadRoles", 1);
});

const getRoles = computed(() => {
  return store.getters["admin/getRoles"];
});

defineExpose({
  validator: v$.value,
  formData: props.inputState,
  checkValidations,
});

const validateAndSave = () => {
  v$.value.$validate();
  emits("saveForm", props.inputState);
};
</script>

<template>
  <div class="flex flex-grow">
    <div class="flex-grow">
      <template v-for="item in inputs" :key="item.index">
        <div class="flex">
          <label class="w-52" :for="`policyDetails${item.title}`"
            >{{ item.title }}
            <span class="text-red" v-if="item.model?.required">*</span>:
          </label>
          <div class="flex-grow mb-5">
            <div class="w-2/3">
              <div class="input-control">
                <input
                  :disabled="props.disabledInputs"
                  v-if="item.tag === 'input'"
                  :id="`policyDetails${item.title}`"
                  class="border border-gray-300 p-1 w-full rounded-md"
                  v-model="item.model.$model"
                  :placeholder="item.placeholder"
                  @blur="item.model.$touch()"
                />
                <v-select
                  :disabled="props.disabledInputs"
                  :multiple="props.multiple_options"
                  :closeOnSelect="!props.multiple_options"
                  v-if="item.tag === 'select'"
                  class="w-full"
                  :options="getRoles"
                  label="label"
                  :filterable="true"
                  placeholder="Please select a role"
                  :id="'role'"
                  v-model="item.model.$model"
                  @blur="item.model.$touch()"
                >
                </v-select>
                <textarea
                  :disabled="props.disabledInputs"
                  v-if="item.tag === 'textarea'"
                  v-model="item.model.$model"
                  rows="4"
                  class="
                    block
                    p-2.5
                    w-full
                    bg-gray-50
                    rounded-lg
                    border border-gray-300
                  "
                  :placeholder="item.placeholder"
                  @blur="item.model.$touch()"
                ></textarea>
              </div>

              <p
                class="text-xs text-red"
                v-for="(error, index) in item.model.$errors"
                :key="index"
              >
                {{ error.$message }}
              </p>
            </div>
          </div>
        </div>
      </template>
      <slot name="extraInputs"></slot>
    </div>
    <div class="flex">
      <div class="" v-if="showSaveButton">
        <div
          @click="validateAndSave"
          class="px-3 py-2 bg-teal text-white rounded-md cursor-pointer"
        >
          Save
        </div>
      </div>
    </div>
  </div>
</template>